/* eslint-disable max-lines */
import {LinkButton} from '@fl/cmsch-fe-library';
import {isEmpty} from 'lodash/fp';
import React, {FC, memo, useCallback} from 'react';

import {UserDetails} from 'api/gen/UserDetails';
import {ActionButton} from 'common/buttons';
import {SideMenu} from 'layout';
import {useOurTranslation} from 'translations';
import {useUser} from 'user';

import {ListActionModeType} from '../../types/list-action-mode-type';
import {OrderListActions} from '../../types/order-list-actions';
import {ListAction} from '../list-action';

import styles from '../list-action/styles.sass';

const largeActionThreshold = 10000;

const goToCloudnaGenomics = (currentUser: UserDetails): void => {
    window.location.href = `http://cmschdata.cz/clouDNA/login?username=${currentUser.id}&password=${currentUser.id}`;
};

interface Props {
    selected: Array<number>;
    total: number;
    listActionsActive: Array<OrderListActions>;
    cancelSubsidiesByIds(): void;
    cancelSubsidiesByFilter(withoutSelected: boolean): void;
    isolateByIds(): void;
    isolateByFilter(withoutSelected: boolean): void;
    createGenotypeExportByIds(): void;
    createGenotypeExportByFilter(withoutSelected: boolean): void;
    setExportBillingBaseMode(mode: ListActionModeType): void;
    closeOrdersForBillingByIds(): void;
    closeOrdersForBillingByFilter(withoutSelected: boolean): void;
    setBulkEditOrderIds(): void;
    setBulkEditOrderFilter(withoutSelected: boolean): void;
    deselectAll(): void;
    exportOrdersByIds(): void;
    exportOrdersByFilter(withoutSelected: boolean): void;
    exportByAssociationByIds(): void;
    exportByAssociationByFilter(withoutSelected: boolean): void;
    exportForGermanyByIds(): void;
    exportForGermanyByFilter(withoutSelected: boolean): void;
    exportCatalogueListsByIds(): void;
    exportCatalogueListsByFilter(withoutSelected: boolean): void;
    exportProtocolsByIds(): void;
    exportProtocolsByFilter(withoutSelected: boolean): void;
    exportCdcbByIds(): void;
    exportCdcbByFilter(withoutSelected: boolean): void;
    setGenerateSampleSheetMode(mode: ListActionModeType): void;
    cancelOrdersBillingByIds(): void;
    cancelOrdersBillingByFilter(withoutSelected: boolean): void;
    deleteOrdersByIds(): void;
    deleteOrdersByFilter(withoutSelected: boolean): void;
}

// eslint-disable-next-line max-lines-per-function
const ListActionsBase: FC<Props> = props => {
    const {
        total,
        selected,
        listActionsActive,
        setExportBillingBaseMode,
        exportOrdersByIds,
        exportOrdersByFilter,
        exportByAssociationByIds,
        exportByAssociationByFilter,
        exportForGermanyByIds,
        exportForGermanyByFilter,
        exportCatalogueListsByIds,
        exportCatalogueListsByFilter,
        exportProtocolsByIds,
        exportProtocolsByFilter,
        cancelSubsidiesByIds,
        cancelSubsidiesByFilter,
        isolateByIds,
        isolateByFilter,
        closeOrdersForBillingByIds,
        closeOrdersForBillingByFilter,
        createGenotypeExportByIds,
        createGenotypeExportByFilter,
        setBulkEditOrderIds,
        setBulkEditOrderFilter,
        exportCdcbByIds,
        exportCdcbByFilter,
        deselectAll,
        setGenerateSampleSheetMode,
        // cancelOrdersBillingByIds,
        // cancelOrdersBillingByFilter,
        // deleteOrdersByIds,
        // deleteOrdersByFilter,
    } = props;

    const {t} = useOurTranslation('orders/ListActions');

    const {
        currentUser,
        isRoleLaborer,
        isRoleLaborerOrBreeder,
        isRoleAssociationOrBreeder,
        isRoleBreeder,
        isRoleAssociation,
        isRoleBreedingUnion,
        isMeatAssociation,
        isHolsteinAssociation,
    } = useUser();

    const isLargeAction = total > largeActionThreshold;

    const openCloudnaGenomics = useCallback(() => {
        if (currentUser) goToCloudnaGenomics(currentUser);
    }, [currentUser]);

    const showBillingBaseFormSelected = useCallback(() =>
        setExportBillingBaseMode('selected')
    , [setExportBillingBaseMode]);

    const showBillingBaseFormFilter = useCallback((withoutSelected: boolean) =>
        setExportBillingBaseMode(withoutSelected ? 'filterWithoutSelected' : 'filter')
    , [setExportBillingBaseMode]);

    const showGenerateSampleSheetFormSelected = useCallback(() =>
        setGenerateSampleSheetMode('selected')
    , [setGenerateSampleSheetMode]);

    const showGenerateSampleSheetFormFilter = useCallback((withoutSelected: boolean) =>
        setGenerateSampleSheetMode(withoutSelected ? 'filterWithoutSelected' : 'filter')
    , [setGenerateSampleSheetMode]);

    return (
        <SideMenu
            title={t('menuTitle')}
            horizontal
        >
            {isRoleLaborerOrBreeder && (
                <div
                    className={styles.listAction}
                    data-test-id="table-action"
                >
                    <LinkButton
                        to="/orders/new"
                        icon="plusOutlined"
                        block
                        bottomMargin
                    >
                        {t('createNew')}
                    </LinkButton>
                </div>
            )}

            {!isRoleBreedingUnion && (
                <ListAction
                    icon="exportOutlined"
                    title={t('exportOrders')}
                    ignoreMaxLimit
                    onFilterClick={exportOrdersByFilter}
                    filterSize={total}
                    onSelectedClick={exportOrdersByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('exportOrders')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeExport')}
                />
            )}

            {isRoleAssociation && (
                <ListAction
                    icon="exportOutlined"
                    title={t('exportOrdersByAssociation')}
                    onFilterClick={exportByAssociationByFilter}
                    filterSize={total}
                    onSelectedClick={exportByAssociationByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('exportOrdersByAssociation')}
                />
            )}

            {isRoleLaborer && (
                <ListAction
                    icon="exportOutlined"
                    title={t('exportOrdersForGermany')}
                    ignoreMaxLimit
                    onFilterClick={exportForGermanyByFilter}
                    filterSize={total}
                    onSelectedClick={exportForGermanyByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('exportOrdersForGermany')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeExport')}
                />
            )}

            {isRoleAssociationOrBreeder && (
                <ListAction
                    icon="fileTextOutlined"
                    title={t('exportCatalogueLists')}
                    onFilterClick={exportCatalogueListsByFilter}
                    ignoreMaxLimit
                    filterSize={total}
                    onSelectedClick={exportCatalogueListsByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('exportCatalogueLists')}
                />
            )}

            {(isRoleAssociation || isRoleBreedingUnion) && (
                <ListAction
                    icon="fileTextOutlined"
                    title={t('protocols')}
                    onFilterClick={exportProtocolsByFilter}
                    filterSize={total}
                    onSelectedClick={exportProtocolsByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('protocols')}
                    ignoreMaxLimit
                />
            )}

            {isRoleAssociation && (
                <ListAction
                    icon="fileTextOutlined"
                    title={t('cancelSubsidy')}
                    onFilterClick={cancelSubsidiesByFilter}
                    filterSize={total}
                    onSelectedClick={cancelSubsidiesByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('cancelSubsidy')}
                />
            )}

            {isRoleLaborer && (
                <ListAction
                    icon="fileTextOutlined"
                    title={t('generateSampleSheet')}
                    onFilterClick={showGenerateSampleSheetFormFilter}
                    filterSize={total}
                    onSelectedClick={showGenerateSampleSheetFormSelected}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('generateSampleSheet')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeSampleSheet')}
                />
            )}

            {isRoleLaborer && (
                <ListAction
                    icon="filterOutlined"
                    title={t('massIsolation')}
                    onFilterClick={isolateByFilter}
                    filterSize={total}
                    onSelectedClick={isolateByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('massIsolation')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeIsolation')}
                />
            )}

            {isRoleLaborer && (
                <ListAction
                    icon="fileOutlined"
                    title={t('closeOrdersForBilling')}
                    onFilterClick={closeOrdersForBillingByFilter}
                    filterSize={total}
                    onSelectedClick={closeOrdersForBillingByIds}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('closeOrdersForBilling')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeCloseForBilling')}
                />
            )}

            {/* todo turn on when api is ready (7345) */}
            {/* {isRoleLaborer && (
                <ListAction
                    icon="closeSquareOutlined"
                    title={t('cancelOrdersBilling')}
                    onFilterClick={cancelOrdersBillingByFilter}
                    filterSize={total}
                    onSelectedClick={cancelOrdersBillingByIds}
                    selectedSize={selected.length}
                />
            )} */
            }

            {isRoleLaborer && (
                <ListAction
                    icon="fileTextOutlined"
                    title={t('billingBase')}
                    onFilterClick={showBillingBaseFormFilter}
                    filterSize={total}
                    onSelectedClick={showBillingBaseFormSelected}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('billingBase')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeBilling')}
                />
            )}

            {(isRoleLaborer || isMeatAssociation || isHolsteinAssociation) && (
                <ListAction
                    icon="exportOutlined"
                    title={t('exportGenotypes')}
                    onSelectedClick={createGenotypeExportByIds}
                    filterSize={total}
                    onFilterClick={createGenotypeExportByFilter}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('exportGenotypes')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeExport')}
                />
            )}

            {isRoleBreeder && (
                <div className={styles.listAction}>
                    <ActionButton
                        onClick={openCloudnaGenomics}
                        icon="exportOutlined"
                    >
                        {t('openCloudnaGenomics')}
                    </ActionButton>
                </div>
            )}

            {isRoleLaborer && (
                <ListAction
                    icon="editOutlined"
                    title={t('bulkUpdateOrder')}
                    onSelectedClick={setBulkEditOrderIds}
                    filterSize={total}
                    onFilterClick={setBulkEditOrderFilter}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('bulkUpdateOrder')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeUpdate')}
                />
            )}

            {/* todo turn on when api is ready (9547) */}
            {/* {isRoleLaborer && (
                <ListAction
                    icon="deleteOutlined"
                    title={t('deleteOrders')}
                    onSelectedClick={deleteOrdersByIds}
                    filterSize={total}
                    onFilterClick={deleteOrdersByFilter}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('deleteOrders')}
                />
            )} */
            }

            {isRoleLaborer && (
                <ListAction
                    icon="exportOutlined"
                    title={t('exportCdcb')}
                    ignoreMaxLimit
                    onSelectedClick={exportCdcbByIds}
                    filterSize={total}
                    onFilterClick={exportCdcbByFilter}
                    selectedSize={selected.length}
                    loading={listActionsActive.includes('exportCdcb')}
                    enableConfirmFilter={isLargeAction}
                    confirmFilterMessage={t('confirmLargeExport')}
                />
            )}

            <div
                className={styles.listAction}
                data-test-id="table-action"
            >
                <div data-test-id="deselect-all">
                    <ActionButton
                        onClick={deselectAll}
                        type="cancel"
                        disabled={isEmpty(selected)}
                    >
                        {t('deselectAll', {total: selected.length})}
                    </ActionButton>
                </div>
            </div>
        </SideMenu>
    );
};

export const ListActions = memo(ListActionsBase);
